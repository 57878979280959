<template>
  <Dialog :title="title" :loading="loading" confirm="Fortsæt" @close="onClose">
    <template #content>
      <div v-if="isRepeating" class="mt-6 space-y-3">
        <div class="relative flex items-start">
          <div class="flex h-5 items-center">
            <input
              id="this"
              v-model="actionContext"
              value="THIS"
              aria-describedby="this-description"
              name="plan"
              type="radio"
              checked
              class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
          </div>
          <div class="ml-3 text-sm">
            <label for="this" class="font-medium text-gray-700">Denne begivenhed</label>
          </div>
        </div>

        <div class="relative flex items-start">
          <div class="flex h-5 items-center">
            <input
              id="this_and_coming"
              v-model="actionContext"
              value="THIS_AND_COMING"
              aria-describedby="this_and_coming-description"
              name="plan"
              type="radio"
              class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
          </div>
          <div class="ml-3 text-sm">
            <label for="this_and_coming" class="font-medium text-gray-700"> Denne og efterfølgende begivenheder </label>
          </div>
        </div>

        <div class="relative flex items-start">
          <div class="flex h-5 items-center">
            <input
              id="all"
              v-model="actionContext"
              value="ALL"
              aria-describedby="all-description"
              name="plan"
              type="radio"
              class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
          </div>
          <div class="ml-3 text-sm">
            <label for="all" class="font-medium text-gray-700">Alle begivenheder</label>
          </div>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import gql from "graphql-tag";
import Dialog from "~/components/dialogs/Dialog";

export const deleteEventMutation = gql`
  mutation deleteEvent($id: ID!, $actionContext: EventActionContextEnum!) {
    destroyEvent(id: $id, actionContext: $actionContext) {
      success
      deletedCount
    }
  }
`;

export default {
  components: { Dialog },

  props: {
    id: {
      type: String,
      required: true,
    },

    isRepeating: Boolean,
  },

  emits: ["close"],

  data() {
    return {
      actionContext: "THIS",
      loading: false,
    };
  },

  computed: {
    title() {
      if (this.isRepeating) {
        return "Slet gentagende begivenhed?";
      }
      return "Slet begivenhed?";
    },
  },

  methods: {
    onClose(accepted) {
      if (accepted) {
        this.deleteEvent();
      } else {
        this.$emit("close", false);
      }
    },

    async deleteEvent() {
      this.loading = true;

      try {
        const response = await this.$apollo.mutate({
          mutation: deleteEventMutation,
          variables: {
            id: this.id,
            actionContext: this.actionContext,
          },
          refetchQueries: ["studentScheduleEventsQuery", "educationModuleEvents", "fetchTeamSchedule"],
        });

        const { deletedCount } = response.data.destroyEvent;
        const multiple = deletedCount > 1;

        this.$toast.success(`Slettede ${deletedCount} ${multiple ? "begivenheder" : "begivenhed"}`);

        this.$emit("close");
      } catch (error) {
        if (error.graphQLErrors[0].extensions.code === "DESTROY_EVENT_BLOCKED") {
          this.$toast.error("Der opstod en fejl", "En eller flere begivenheder har tid registreret og kan derfor ikke slettes", {
            data: {
              actions: [
                {
                  text: "Vis begivenheder",
                  primary: true,
                  click: () => {
                    this.$toast.clear();
                    this.$openModal("BlockedEventsModal", {
                      eventIds: error.graphQLErrors[0].extensions.eventIds,
                    });
                  },
                },
                {
                  text: "Luk",
                  click: () => this.$toast.clear(),
                },
              ],
            },
          });
        } else {
          this.$errorHandler.handleError(error);
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
