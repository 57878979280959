<template>
  <div class="date-input">
    <Label v-if="label" :size="size" :for="id || name" :required="required">{{ label }}</Label>
    <div class="relative">
      <Tippy
        ref="tippy"
        :options="{
          placement: 'bottom-start',
          arrow: false,
          trigger: 'click',
          popperOptions: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 4],
                },
              },
            ],
          },
        }"
      >
        <template #trigger>
          <input
            :id="id || name"
            ref="input"
            :type="type"
            :name="name || id"
            class="date-input__input block w-full min-w-48 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            :class="[
              size === 'sm' && 'h-6 py-0 pl-2 pr-4 text-sm leading-6',
              size === 'md' && 'h-8 pl-2 pr-4 text-sm leading-8',
              size === 'lg' && 'h-10 pl-3 pr-5 text-sm leading-10',
              disabled ? 'text-gray-400' : 'text-gray-900',
            ]"
            :placeholder="placeholder"
            :aria-describedby="(id || name) + 'Optional'"
            :autocomplete="autocomplete"
            :disabled="disabled"
            :readonly="readonly"
            :min="toString(min) || null"
            :max="toString(max) || null"
            :step="step"
            :required="required"
            :value="model"
            @input="$event.target.validity.valid && (model = $event.target.value)"
            @keydown="onPress"
            @click.prevent
            @change="$emit('change', $event)"
            @blur="$emit('blur', $event)"
          />
        </template>
        <template #content>
          <div class="p-2" @mousedown.prevent>
            <DatePicker
              :modelValue="modelValue"
              :min="min"
              :max="max"
              @update:modelValue="
                (event) => {
                  model = event;
                  $refs.tippy.hide();
                }
              "
            />
          </div>
        </template>
      </Tippy>
      <div
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center"
        :class="[size === 'lg' ? 'mr-3' : 'mr-2', disabled ? 'text-gray-400' : 'text-gray-700']"
      >
        <svgicon v-if="!disabled" name="outline-calendar" class="moz-position" :class="[size === 'sm' ? 'h-4 w-4' : 'h-5 w-5']" :fill="false" />
      </div>
      <BaseIconButton
        v-if="!required && modelValue"
        class="absolute inset-y-0 right-6 top-0.5 mr-3 flex cursor-pointer items-center text-gray-700"
        :disabled="disabled"
        icon="outline-x-circle"
        data-testid="date-clear-button"
        @click.stop="clear"
      />
    </div>
    <BaseErrorList :errors="errors" />
    <BaseWarningList :warnings="warnings" />
  </div>
</template>

<script>
import Tippy from "~/components/tippy/Tippy";
import Label from "~/components/inputs/Label";
import DatePicker from "~/components/calendars/DatePicker";

export default {
  components: { Tippy, Label, DatePicker },

  props: {
    id: String,
    name: String,
    label: String,
    modelValue: [String, Date],
    placeholder: String,
    min: [String, Date],
    max: [String, Date],
    step: Number,
    type: {
      type: String,
      default: "date",
      validator(value) {
        return ["date", "datetime", "datetime-local"].includes(value);
      },
    },

    required: {
      type: Boolean,
      default: false,
    },

    autocomplete: {
      type: String,
      default: "off",
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String,
      default: "lg",
      validator(value) {
        return ["sm", "md", "lg"].includes(value);
      },
    },

    errors: Array,
    warnings: Array,
  },

  emits: ["update:modelValue", "change", "blur"],

  computed: {
    model: {
      get() {
        return this.toString(this.modelValue);
      },

      set(newValue) {
        const shouldEmitString = typeof this.modelValue === "string";

        if (shouldEmitString) {
          newValue = this.toString(newValue);
        } else {
          newValue = this.toDate(newValue);
        }

        this.$emit("update:modelValue", newValue);
        this.$emit("change", newValue);
      },
    },
  },

  methods: {
    clear() {
      this.model = null;
    },

    toDate(input) {
      if (!input) {
        return null;
      }

      if (input instanceof Date) {
        return input;
      }

      return new Date(input);
    },

    toString(input) {
      if (!input) {
        return "";
      }

      if (typeof input === "string") {
        return input;
      }

      return new Date(input).toISOString().split("T")[0];
    },

    onPress(event) {
      if (event.key === "Backspace") return;

      if (event.key === "Escape") {
        event.preventDefault();
        event.stopPropagation();
        this.$refs.tippy.hide();
      } else if (event.key === "Tab") {
        event.preventDefault();
        this.$refs.tippy.hide();
      } else if ([...Array(10).keys()].includes(parseInt(event.key)) || event.key === "ArrowUp" || event.key === "ArrowDown") {
        this.$refs.tippy.hide();
      } else if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
        this.$refs.tippy.hide();
      } else {
        event.preventDefault();
      }
    },
  },
};
</script>

<style lang="postcss">
/* Firefox  */
@supports (-moz-appearance: none) {
  .moz-position {
    @apply relative z-100 w-5 bg-white;
    right: 0.5rem;
  }
}

.date-input__input::-webkit-inner-spin-button,
.date-input__input::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.date-input__date-picker {
  @apply w-60 p-2 shadow-none;
}

.date-input__date-picker .vuecal__arrow {
  @apply flex;
}

.date-input__date-picker .vuecal__title-bar {
  @apply flex min-h-0 items-center bg-white py-2 text-sm text-gray-900;
}

.date-input__date-picker .vuecal__heading {
  @apply h-auto py-1;
}

.date-input__date-picker .vuecal__weekdays-headings {
  @apply m-0 border-0;
}

.date-input__date-picker .weekday-label {
  @apply text-xs font-bold text-gray-900;
}

.date-input__date-picker .vuecal__cells.month-view {
  @apply m-0;
}

.date-input__date-picker .vuecal__cell {
  @apply h-8 w-8 rounded-md text-gray-900 transition-none;
}

.date-input__date-picker .vuecal__cell::before {
  @apply hidden;
}

.date-input__date-picker .vuecal__cell--today {
  @apply bg-transparent font-semibold;
  @apply bg-white font-medium text-blue-600;
}

.date-input__date-picker .vuecal__cell:hover {
  @apply bg-gray-100;
}

.date-input__date-picker .vuecal__cell--selected {
  @apply bg-blue-500 font-medium;
}

.date-input__date-picker .vuecal__cell--out-of-scope {
  @apply text-gray-400;
}

.date-input__date-picker .vuecal__cell--disabled {
  @apply text-gray-300;
}

.date-input__date-picker .vuecal__cell--selected .vuecal__cell-date {
  @apply text-white;
}

.date-input__date-picker .vuecal__cell--today:not(.vuecal__cell--selected) {
  @apply bg-white font-medium text-blue-600;
}
</style>
